import * as mui from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'

import {Field, FieldProps} from '../Field'
import {useFormikContext} from 'formik'


export function FieldSoB({
  isPdf,
  ...props
}: {
  isPdf: boolean,
} & FieldProps) {
  const theme = useTheme()
  const { values } = useFormikContext<any>()

  if (isPdf) {
    return (
      <mui.Typography
        sx={{
          color: '#0000FF',
          fontSize: theme.typography.caption.fontSize,
          fontWeight: 'bold',
          textAlign: 'center',
          ...props.sx,
        }}
      >
        {values[props.name]}
      </mui.Typography>
    )
  }
  return (
    <Field
      {...props}
      sx={{
        '& fieldset': {
          border: 'none',
          p: 0,
        },
        '& input': {
          color: '#0000FF',
          fontSize: theme.typography.caption.fontSize,
          fontWeight: 'bold',
          p: 0,
          textAlign: 'center',
        },
        '& .Mui-error input': {
          backgroundColor: '#FBC1CD',
        },
        '& .Mui-error.MuiFormHelperText-root': {
          display: 'none',
        },
        ...props.sx,
      }}
    />
  )
}