import { valuesType } from 'pages/Index/Index'


function toFixed(value: number, decimal: number) {
  if (typeof value === 'string') {
    value = parseFloat(value)
  }
  return value.toFixed(decimal) as unknown as number
}


export function renderValues(values: valuesType, currencyCode: string) {
  return {
    ...values,
    investmentDmiFarOff: toFixed(values.investmentDmiFarOff, 1),
    investmentDmiCloseUp: toFixed(values.investmentDmiCloseUp, 1),
    investmentDmiFresh: toFixed(values.investmentDmiFresh, 1),
    investmentDmiEarlyMidLact: toFixed(values.investmentDmiEarlyMidLact, 1),
    investmentDmiLateLact: toFixed(values.investmentDmiLateLact, 1),
    investmentDmCostCurrentFarOff: toFixed(values.investmentDmCostCurrentFarOff, 3),
    investmentDmCostCurrentCloseUp: toFixed(values.investmentDmCostCurrentCloseUp, 3),
    investmentDmCostCurrentFresh: toFixed(values.investmentDmCostCurrentFresh, 3),
    investmentDmCostCurrentEarlyMidLact: toFixed(values.investmentDmCostCurrentEarlyMidLact, 3),
    investmentDmCostCurrentLateLact: toFixed(values.investmentDmCostCurrentLateLact, 3),
    shortTermReturnsMilkCurrent: toFixed(values.shortTermReturnsMilkCurrent, 1),
    shortTermReturnsMilkFmmoPrice: toFixed(values.shortTermReturnsMilkFmmoPrice, 4),
    shortTermReturnsFatFmmoPrice: toFixed(values.shortTermReturnsFatFmmoPrice, 4),
    shortTermReturnsProteinFmmoPrice: toFixed(values.shortTermReturnsProteinFmmoPrice, 4),
    shortTermReturnsOtherSolidsFmmoPrice: toFixed(values.shortTermReturnsOtherSolidsFmmoPrice, 4),
    shortTermReturnsPremiumFmmoPrice: toFixed(values.shortTermReturnsPremiumFmmoPrice, 4),
    longTermReturnsCullValueCurrent: toFixed(values.longTermReturnsCullValueCurrent, 3),
    longTermReturnsMinimumMilkToBreedCurrent: toFixed(values.longTermReturnsMinimumMilkToBreedCurrent, 0),

    productPrice: toFixed(values.productPrice, currencyCode === 'JPY' ? 0 : 2),
    longTermReturnsCalfValueCurrent: toFixed(values.longTermReturnsCalfValueCurrent, 0),
    longTermReturnsReproductionCostCurrent: toFixed(values.longTermReturnsReproductionCostCurrent, 0),
    longTermReturnsReplacementCostCurrent: toFixed(values.longTermReturnsReplacementCostCurrent, 0),
    longTermReturnsMastitisCostPerCase: toFixed(values.longTermReturnsMastitisCostPerCase, 0),
    longTermReturnsKetosisClinicalCostPerCase: toFixed(values.longTermReturnsKetosisClinicalCostPerCase, 0),
    longTermReturnsKetosisSubclinicalCostPerCase: toFixed(values.longTermReturnsKetosisSubclinicalCostPerCase, 0),
    longTermReturnsMetritisCostPerCase: toFixed(values.longTermReturnsMetritisCostPerCase, 0),
  }
}