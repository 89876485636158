import * as mui from '@mui/material'
import React from 'react'
import * as Sentry from '@sentry/react'


export function PageNotFound() {
  Sentry.captureMessage('Page not found')
  return (
    <mui.Container maxWidth="lg" sx={{p: 5}}>
      This page does not exist.
    </mui.Container>
  )
}