import * as mui from '@mui/material'
import React from 'react'

import {Calculate, Currency, ProductFeedingRate, Query} from 'types/graphql/schema'
import {UseQueryBaseReturnType} from 'utils/graphql'

import {Chart} from './Chart'
import {Controls, NotesState} from './Controls'
import {Summary} from './Summary'


export function Dashboard(props: {
  calculationResult: Calculate
  constants: UseQueryBaseReturnType & {data: Query}
  currencies: Currency[]
  feedingRates: ProductFeedingRate[]
  pdfRef?: React.RefObject<HTMLDivElement>
  notesState: NotesState
}) {
  return (
    <mui.Box
      sx={{
        ...(props.pdfRef
          ? {
            position: 'absolute',
            top: '30px',
            left: '27px',
          }
          : {}
        )
      }}
    >
      <mui.Stack
        gap={2}
        mb={3}
        sx={{
          ...(props.pdfRef
            ? {
              position: 'fixed',
              width: '350px',
            }
            : {}
          )
        }}
      >
        <Chart calculationResult={props.calculationResult}/>
        <Summary calculationResult={props.calculationResult}/>
        <Controls
          currencies={props.currencies}
          feedingRates={props.feedingRates}
          isPdf={!props.pdfRef}
          pdfRef={props.pdfRef}
          products={props.constants.data.products}
          notesState={props.notesState}
        />
      </mui.Stack>
    </mui.Box>
  )
}