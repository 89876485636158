import React from 'react'

import { Query } from 'types/graphql/schema'
import { useQuery } from 'utils/graphql'


type currentUser = Query['currentUser']

export const userContext = React.createContext({} as {
  data?: currentUser
  isFetching: boolean
  refetch: CallableFunction
  spinner: JSX.Element | null
})

export function useUserContext() {
  const user = useQuery(
    {
      currentUser: {
        __scalar: 1,
      }
    },
    'currentUser'
  )
  const refetch = user.refetch
  React.useEffect(() => refetch(), [refetch])
  return user
}
