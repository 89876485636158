import { CircularProgress } from "@mui/material"
import React from "react"


export function CenteredSpinner() {
    return (
        <div style={{display: 'flex', justifyContent: 'center', marginTop: 'calc(50vh - 50px)'}}>
            <CircularProgress size={100} />
        </div>
    )
}