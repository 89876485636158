import { Observable } from 'graphql-typed-client'

export interface Query {
  countries: Country[]
  currencies: Currency[]
  currentUser: User | null
  products: Product[]
  standards: Standards[]
  ping: String
  __typename: 'Query'
}

export interface Country {
  name: String
  code: String
  __typename: 'Country'
}

/** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
export type String = string

export interface Currency {
  id: ID
  name: String
  code: String
  sign: String
  conversionRate: Float
  conversionRateDate: DateTime
  standardsregionSet: StandardsRegion[]
  __typename: 'Currency'
}

/** The `ID` scalar type represents a unique identifier, often used to refetch an object or as key for a cache. The ID type appears in a JSON response as a String; however, it is not intended to be human-readable. When expected as an input type, any string (such as `"4"`) or integer (such as `4`) input value will be accepted as an ID. */
export type ID = string

/** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](https://en.wikipedia.org/wiki/IEEE_floating_point). */
export type Float = number

/**
 * The `DateTime` scalar type represents a DateTime
 * value as specified by
 * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
 */
export type DateTime = any

export interface StandardsRegion {
  id: ID
  currency: Currency
  name: String
  units: MainStandardsRegionUnitsChoices
  standards: Standards | null
  __typename: 'StandardsRegion'
}

/** An enumeration. */
export enum MainStandardsRegionUnitsChoices {
  /** Metric */
  METRIC = 'METRIC',
  /** Imperial */
  IMPERIAL = 'IMPERIAL',
}

export interface Standards {
  id: ID
  region: StandardsRegion
  feedingRates: FeedingRates
  fmmoPricing: FmmoPricing
  milkPerformance: MilkPerformance
  milkPerformanceDiff: MilkPerformance
  reproductivePerformance: ReproductivePerformance
  economicPerformance: EconomicPerformance
  mastitisClinicalPerformance: DiseasePerformance
  ketosisClinicalPerformance: DiseasePerformance
  ketosisSubclinicalPerformance: DiseasePerformance
  metritisPerformance: DiseasePerformance
  __typename: 'Standards'
}

export interface FeedingRates {
  id: ID
  herdComposition: ConstantsPerPhase
  dmi: ConstantsPerPhase
  currentFeedCosts: ConstantsPerPhase
  standards: Standards | null
  productFeedingRates: ProductFeedingRate[]
  __typename: 'FeedingRates'
}

export interface ConstantsPerPhase {
  farOff: Float
  closeUp: Float
  fresh: Float
  earlyMidLact: Float
  lateLact: Float
  __typename: 'ConstantsPerPhase'
}

export interface ProductFeedingRate {
  farOff: Float
  closeUp: Float
  fresh: Float
  earlyMidLact: Float
  lateLact: Float
  constantsperphasePtr: ConstantsPerPhase
  id: ID
  feedingRates: FeedingRates
  product: Product
  herdComposition: FeedingRates | null
  dmi: FeedingRates | null
  currentFeedCosts: FeedingRates | null
  productfeedingrate: ProductFeedingRate | null
  __typename: 'ProductFeedingRate'
}

export interface Product {
  id: ID
  name: String
  price: Float
  __typename: 'Product'
}

export interface FmmoPricing {
  id: ID
  milk: Float
  fat: Float
  protein: Float
  otherSolids: Float
  premium: Float
  standards: Standards | null
  __typename: 'FmmoPricing'
}

export interface MilkPerformance {
  milk: Float
  fat: Float
  protein: Float
  otherSolids: Float
  __typename: 'MilkPerformance'
}

export interface ReproductivePerformance {
  lastMonthToBreed: Float
  minimumMilkToBreed: Float
  pregnancyRate: Float
  pregnancyRateDiff: Float
  pregnancyLoss: Float
  pregnancyLossDiff: Float
  __typename: 'ReproductivePerformance'
}

export interface EconomicPerformance {
  reproductionCost: Float
  replacementCost: Float
  cullValue: Float
  calfValue: Float
  cullingRate: Float
  __typename: 'EconomicPerformance'
}

export interface DiseasePerformance {
  regularIncidence: Float
  reducedIncidence: Float
  usdCase: Float
  reductionPercent: Float
  __typename: 'DiseasePerformance'
}

export interface User {
  email: String
  __typename: 'User'
}

export interface Mutation {
  calculate: Calculate
  login: Login | null
  register: Register | null
  ping: String
  __typename: 'Mutation'
}

export enum Units {
  METRIC = 'METRIC',
  IMPERIAL = 'IMPERIAL',
}

export enum ResultsUnitsAmount {
  COW = 'COW',
  HERD = 'HERD',
}

export enum ResultsUnitsPeriod {
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

/** The `Int` scalar type represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1. */
export type Int = number

/** The `Boolean` scalar type represents `true` or `false`. */
export type Boolean = boolean

export interface Calculate {
  currency: Currency
  product: Product
  productPriceConverted: Float
  iofcShortAndLongTerm: Float
  iofcShortTerm: Float
  resultsUnits: String
  roiShortAndLongTerm: Float
  roiShortTerm: Float
  weightUnits: String
  investment: CalculationsInvestment
  shortTermReturns: CalculationsShortTermReturns
  longTermReturnsReproduction: CalculationsLongTermReturnsReproduction
  longTermReturnsHealth: CalculationsLongTermReturnsHealth
  __typename: 'Calculate'
}

export interface CalculationsInvestment {
  cowsAmount: ValuesPerPhase
  productPricePerCowDay: ValuesPerPhase
  dmiWithProduct: ValuesPerPhase
  dmCostWithProduct: ValuesPerPhase
  feedCostPerCowDay: ValuesPerPhase
  feedCostPerCowDayWithProduct: ValuesPerPhase
  totalFeedCostPerCowYear: Float
  totalFeedCostPerCowYearWithProduct: Float
  totalFeedCostPerCowYearDifference: Float
  totalFeedCostDifference: Float
  __typename: 'CalculationsInvestment'
}

export interface ValuesPerPhase {
  farOff: Float | null
  closeUp: Float | null
  fresh: Float | null
  earlyMidLact: Float | null
  lateLact: Float | null
  __typename: 'ValuesPerPhase'
}

export interface CalculationsShortTermReturns {
  milkPerCowDayWithProduct: Float
  fatPercent: Float
  proteinPercent: Float
  otherSolidsPercent: Float
  fatPerCowDay: Difference
  proteinPerCowDay: Difference
  otherSolidsPerCowDay: Difference
  ecmPerCowDay: Difference
  impactCwtCost: Difference
  impactPerCowDayCost: Difference
  impactPerCowYearCost: Difference
  totalImpactCost: Difference
  __typename: 'CalculationsShortTermReturns'
}

export interface Difference {
  current: Float | null
  withProduct: Float | null
  difference: Float | null
  __typename: 'Difference'
}

export interface CalculationsLongTermReturnsReproduction {
  rollingHerdAvg: Int
  pregnancyRateWithProduct: Float
  pregnancyLossWithProduct: Float
  calfSales: Difference
  mortalityCost: Difference
  reproductiveCost: Difference
  reproductiveCullingCost: Difference
  nonReproductiveCullingCost: Difference
  totalReturns: Difference
  totalReturnsFinal: Difference
  __typename: 'CalculationsLongTermReturnsReproduction'
}

export interface CalculationsLongTermReturnsHealth {
  mastitis: Difference
  ketosisClinical: Difference
  ketosisSubclinical: Difference
  metritis: Difference
  totalSavings: Difference
  totalSavingsFinal: Difference
  __typename: 'CalculationsLongTermReturnsHealth'
}

export interface Login {
  success: Boolean
  __typename: 'Login'
}

export interface Register {
  success: Boolean
  __typename: 'Register'
}

export interface QueryRequest {
  countries?: CountryRequest
  currencies?: CurrencyRequest
  currentUser?: UserRequest
  products?: ProductRequest
  standards?: StandardsRequest
  ping?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CountryRequest {
  name?: boolean | number
  code?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CurrencyRequest {
  id?: boolean | number
  name?: boolean | number
  code?: boolean | number
  sign?: boolean | number
  conversionRate?: boolean | number
  conversionRateDate?: boolean | number
  standardsregionSet?: StandardsRegionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface StandardsRegionRequest {
  id?: boolean | number
  currency?: CurrencyRequest
  name?: boolean | number
  units?: boolean | number
  standards?: StandardsRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface StandardsRequest {
  id?: boolean | number
  region?: StandardsRegionRequest
  feedingRates?: FeedingRatesRequest
  fmmoPricing?: FmmoPricingRequest
  milkPerformance?: MilkPerformanceRequest
  milkPerformanceDiff?: MilkPerformanceRequest
  reproductivePerformance?: ReproductivePerformanceRequest
  economicPerformance?: EconomicPerformanceRequest
  mastitisClinicalPerformance?: DiseasePerformanceRequest
  ketosisClinicalPerformance?: DiseasePerformanceRequest
  ketosisSubclinicalPerformance?: DiseasePerformanceRequest
  metritisPerformance?: DiseasePerformanceRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface FeedingRatesRequest {
  id?: boolean | number
  herdComposition?: ConstantsPerPhaseRequest
  dmi?: ConstantsPerPhaseRequest
  currentFeedCosts?: ConstantsPerPhaseRequest
  standards?: StandardsRequest
  productFeedingRates?: ProductFeedingRateRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ConstantsPerPhaseRequest {
  farOff?: boolean | number
  closeUp?: boolean | number
  fresh?: boolean | number
  earlyMidLact?: boolean | number
  lateLact?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ProductFeedingRateRequest {
  farOff?: boolean | number
  closeUp?: boolean | number
  fresh?: boolean | number
  earlyMidLact?: boolean | number
  lateLact?: boolean | number
  constantsperphasePtr?: ConstantsPerPhaseRequest
  id?: boolean | number
  feedingRates?: FeedingRatesRequest
  product?: ProductRequest
  herdComposition?: FeedingRatesRequest
  dmi?: FeedingRatesRequest
  currentFeedCosts?: FeedingRatesRequest
  productfeedingrate?: ProductFeedingRateRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ProductRequest {
  id?: boolean | number
  name?: boolean | number
  price?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface FmmoPricingRequest {
  id?: boolean | number
  milk?: boolean | number
  fat?: boolean | number
  protein?: boolean | number
  otherSolids?: boolean | number
  premium?: boolean | number
  standards?: StandardsRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface MilkPerformanceRequest {
  milk?: boolean | number
  fat?: boolean | number
  protein?: boolean | number
  otherSolids?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ReproductivePerformanceRequest {
  lastMonthToBreed?: boolean | number
  minimumMilkToBreed?: boolean | number
  pregnancyRate?: boolean | number
  pregnancyRateDiff?: boolean | number
  pregnancyLoss?: boolean | number
  pregnancyLossDiff?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface EconomicPerformanceRequest {
  reproductionCost?: boolean | number
  replacementCost?: boolean | number
  cullValue?: boolean | number
  calfValue?: boolean | number
  cullingRate?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface DiseasePerformanceRequest {
  regularIncidence?: boolean | number
  reducedIncidence?: boolean | number
  usdCase?: boolean | number
  reductionPercent?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UserRequest {
  email?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface MutationRequest {
  calculate?: [{ input: CalculationInput }, CalculateRequest]
  login?: [{ email: String; password: String }, LoginRequest]
  register?: [
    { company: String; country: String; email: String; name: String; password: String; password2: String },
    RegisterRequest,
  ]
  ping?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CalculationInput {
  units: Units
  resultsUnitsAmount: ResultsUnitsAmount
  resultsUnitsPeriod: ResultsUnitsPeriod
  currencyId: ID
  productId: ID
  productPrice: Float
  regionId: ID
  herdSize: Int
  aabCents: Int
  isPlusDmi: Boolean
  isAab: Boolean
  investmentHerdStructureFarOff: Float
  investmentHerdStructureCloseUp: Float
  investmentHerdStructureFresh: Float
  investmentHerdStructureEarlyMidLact: Float
  investmentHerdStructureLateLact: Float
  investmentProductGramsPerCowDayCloseUp: Float
  investmentProductGramsPerCowDayFresh: Float
  investmentProductGramsPerCowDayEarlyMidLact: Float
  investmentProductGramsPerCowDayLateLact: Float
  investmentDmiFarOff: Float
  investmentDmiCloseUp: Float
  investmentDmiFresh: Float
  investmentDmiEarlyMidLact: Float
  investmentDmiLateLact: Float
  investmentDmCostCurrentFarOff: Float
  investmentDmCostCurrentCloseUp: Float
  investmentDmCostCurrentFresh: Float
  investmentDmCostCurrentEarlyMidLact: Float
  investmentDmCostCurrentLateLact: Float
  shortTermReturnsMilkCurrent: Float
  shortTermReturnsMilkDifference: Float
  shortTermReturnsMilkFmmoPrice: Float
  shortTermReturnsFatCurrent: Float
  shortTermReturnsFatDifference: Float
  shortTermReturnsFatFmmoPrice: Float
  shortTermReturnsProteinCurrent: Float
  shortTermReturnsProteinDifference: Float
  shortTermReturnsProteinFmmoPrice: Float
  shortTermReturnsOtherSolidsCurrent: Float
  shortTermReturnsOtherSolidsDifference: Float
  shortTermReturnsOtherSolidsFmmoPrice: Float
  shortTermReturnsPremiumFmmoPrice: Float
  longTermReturnsReproductionCostCurrent: Float
  longTermReturnsReplacementCostCurrent: Float
  longTermReturnsCullValueCurrent: Float
  longTermReturnsCalfValueCurrent: Float
  longTermReturnsLastMonthToBreedCurrent: Float
  longTermReturnsMinimumMilkToBreedCurrent: Float
  longTermReturnsCullingRateCurrent: Float
  longTermReturnsPregnancyRateCurrent: Float
  longTermReturnsPregnancyRateDifference: Float
  longTermReturnsPregnancyLossCurrent: Float
  longTermReturnsPregnancyLossDifference: Float
  longTermReturnsMastitisRegular: Float
  longTermReturnsMastitisReduced: Float
  longTermReturnsMastitisCostPerCase: Float
  longTermReturnsKetosisClinicalRegular: Float
  longTermReturnsKetosisClinicalReduced: Float
  longTermReturnsKetosisClinicalCostPerCase: Float
  longTermReturnsKetosisSubClinicalRegular: Float
  longTermReturnsKetosisSubClinicalReduced: Float
  longTermReturnsKetosisSubclinicalCostPerCase: Float
  longTermReturnsMetritisRegular: Float
  longTermReturnsMetritisReduced: Float
  longTermReturnsMetritisCostPerCase: Float
}

export interface CalculateRequest {
  currency?: CurrencyRequest
  product?: ProductRequest
  productPriceConverted?: boolean | number
  iofcShortAndLongTerm?: boolean | number
  iofcShortTerm?: boolean | number
  resultsUnits?: boolean | number
  roiShortAndLongTerm?: boolean | number
  roiShortTerm?: boolean | number
  weightUnits?: boolean | number
  investment?: CalculationsInvestmentRequest
  shortTermReturns?: CalculationsShortTermReturnsRequest
  longTermReturnsReproduction?: CalculationsLongTermReturnsReproductionRequest
  longTermReturnsHealth?: CalculationsLongTermReturnsHealthRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CalculationsInvestmentRequest {
  cowsAmount?: ValuesPerPhaseRequest
  productPricePerCowDay?: ValuesPerPhaseRequest
  dmiWithProduct?: ValuesPerPhaseRequest
  dmCostWithProduct?: ValuesPerPhaseRequest
  feedCostPerCowDay?: ValuesPerPhaseRequest
  feedCostPerCowDayWithProduct?: ValuesPerPhaseRequest
  totalFeedCostPerCowYear?: boolean | number
  totalFeedCostPerCowYearWithProduct?: boolean | number
  totalFeedCostPerCowYearDifference?: boolean | number
  totalFeedCostDifference?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ValuesPerPhaseRequest {
  farOff?: boolean | number
  closeUp?: boolean | number
  fresh?: boolean | number
  earlyMidLact?: boolean | number
  lateLact?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CalculationsShortTermReturnsRequest {
  milkPerCowDayWithProduct?: boolean | number
  fatPercent?: boolean | number
  proteinPercent?: boolean | number
  otherSolidsPercent?: boolean | number
  fatPerCowDay?: DifferenceRequest
  proteinPerCowDay?: DifferenceRequest
  otherSolidsPerCowDay?: DifferenceRequest
  ecmPerCowDay?: DifferenceRequest
  impactCwtCost?: DifferenceRequest
  impactPerCowDayCost?: DifferenceRequest
  impactPerCowYearCost?: DifferenceRequest
  totalImpactCost?: DifferenceRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface DifferenceRequest {
  current?: boolean | number
  withProduct?: boolean | number
  difference?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CalculationsLongTermReturnsReproductionRequest {
  rollingHerdAvg?: boolean | number
  pregnancyRateWithProduct?: boolean | number
  pregnancyLossWithProduct?: boolean | number
  calfSales?: DifferenceRequest
  mortalityCost?: DifferenceRequest
  reproductiveCost?: DifferenceRequest
  reproductiveCullingCost?: DifferenceRequest
  nonReproductiveCullingCost?: DifferenceRequest
  totalReturns?: DifferenceRequest
  totalReturnsFinal?: DifferenceRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CalculationsLongTermReturnsHealthRequest {
  mastitis?: DifferenceRequest
  ketosisClinical?: DifferenceRequest
  ketosisSubclinical?: DifferenceRequest
  metritis?: DifferenceRequest
  totalSavings?: DifferenceRequest
  totalSavingsFinal?: DifferenceRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LoginRequest {
  success?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface RegisterRequest {
  success?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

const Query_possibleTypes = ['Query']
export const isQuery = (obj: { __typename: String }): obj is Query => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Query_possibleTypes.includes(obj.__typename)
}

const Country_possibleTypes = ['Country']
export const isCountry = (obj: { __typename: String }): obj is Country => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Country_possibleTypes.includes(obj.__typename)
}

const Currency_possibleTypes = ['Currency']
export const isCurrency = (obj: { __typename: String }): obj is Currency => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Currency_possibleTypes.includes(obj.__typename)
}

const StandardsRegion_possibleTypes = ['StandardsRegion']
export const isStandardsRegion = (obj: { __typename: String }): obj is StandardsRegion => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return StandardsRegion_possibleTypes.includes(obj.__typename)
}

const Standards_possibleTypes = ['Standards']
export const isStandards = (obj: { __typename: String }): obj is Standards => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Standards_possibleTypes.includes(obj.__typename)
}

const FeedingRates_possibleTypes = ['FeedingRates']
export const isFeedingRates = (obj: { __typename: String }): obj is FeedingRates => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return FeedingRates_possibleTypes.includes(obj.__typename)
}

const ConstantsPerPhase_possibleTypes = ['ConstantsPerPhase']
export const isConstantsPerPhase = (obj: { __typename: String }): obj is ConstantsPerPhase => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ConstantsPerPhase_possibleTypes.includes(obj.__typename)
}

const ProductFeedingRate_possibleTypes = ['ProductFeedingRate']
export const isProductFeedingRate = (obj: { __typename: String }): obj is ProductFeedingRate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ProductFeedingRate_possibleTypes.includes(obj.__typename)
}

const Product_possibleTypes = ['Product']
export const isProduct = (obj: { __typename: String }): obj is Product => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Product_possibleTypes.includes(obj.__typename)
}

const FmmoPricing_possibleTypes = ['FmmoPricing']
export const isFmmoPricing = (obj: { __typename: String }): obj is FmmoPricing => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return FmmoPricing_possibleTypes.includes(obj.__typename)
}

const MilkPerformance_possibleTypes = ['MilkPerformance']
export const isMilkPerformance = (obj: { __typename: String }): obj is MilkPerformance => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MilkPerformance_possibleTypes.includes(obj.__typename)
}

const ReproductivePerformance_possibleTypes = ['ReproductivePerformance']
export const isReproductivePerformance = (obj: { __typename: String }): obj is ReproductivePerformance => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ReproductivePerformance_possibleTypes.includes(obj.__typename)
}

const EconomicPerformance_possibleTypes = ['EconomicPerformance']
export const isEconomicPerformance = (obj: { __typename: String }): obj is EconomicPerformance => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return EconomicPerformance_possibleTypes.includes(obj.__typename)
}

const DiseasePerformance_possibleTypes = ['DiseasePerformance']
export const isDiseasePerformance = (obj: { __typename: String }): obj is DiseasePerformance => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return DiseasePerformance_possibleTypes.includes(obj.__typename)
}

const User_possibleTypes = ['User']
export const isUser = (obj: { __typename: String }): obj is User => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return User_possibleTypes.includes(obj.__typename)
}

const Mutation_possibleTypes = ['Mutation']
export const isMutation = (obj: { __typename: String }): obj is Mutation => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Mutation_possibleTypes.includes(obj.__typename)
}

const Calculate_possibleTypes = ['Calculate']
export const isCalculate = (obj: { __typename: String }): obj is Calculate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Calculate_possibleTypes.includes(obj.__typename)
}

const CalculationsInvestment_possibleTypes = ['CalculationsInvestment']
export const isCalculationsInvestment = (obj: { __typename: String }): obj is CalculationsInvestment => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CalculationsInvestment_possibleTypes.includes(obj.__typename)
}

const ValuesPerPhase_possibleTypes = ['ValuesPerPhase']
export const isValuesPerPhase = (obj: { __typename: String }): obj is ValuesPerPhase => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ValuesPerPhase_possibleTypes.includes(obj.__typename)
}

const CalculationsShortTermReturns_possibleTypes = ['CalculationsShortTermReturns']
export const isCalculationsShortTermReturns = (obj: { __typename: String }): obj is CalculationsShortTermReturns => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CalculationsShortTermReturns_possibleTypes.includes(obj.__typename)
}

const Difference_possibleTypes = ['Difference']
export const isDifference = (obj: { __typename: String }): obj is Difference => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Difference_possibleTypes.includes(obj.__typename)
}

const CalculationsLongTermReturnsReproduction_possibleTypes = ['CalculationsLongTermReturnsReproduction']
export const isCalculationsLongTermReturnsReproduction = (obj: {
  __typename: String
}): obj is CalculationsLongTermReturnsReproduction => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CalculationsLongTermReturnsReproduction_possibleTypes.includes(obj.__typename)
}

const CalculationsLongTermReturnsHealth_possibleTypes = ['CalculationsLongTermReturnsHealth']
export const isCalculationsLongTermReturnsHealth = (obj: {
  __typename: String
}): obj is CalculationsLongTermReturnsHealth => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CalculationsLongTermReturnsHealth_possibleTypes.includes(obj.__typename)
}

const Login_possibleTypes = ['Login']
export const isLogin = (obj: { __typename: String }): obj is Login => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Login_possibleTypes.includes(obj.__typename)
}

const Register_possibleTypes = ['Register']
export const isRegister = (obj: { __typename: String }): obj is Register => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Register_possibleTypes.includes(obj.__typename)
}

export interface QueryPromiseChain {
  countries: { execute: (request: CountryRequest, defaultValue?: Country[]) => Promise<Country[]> }
  currencies: { execute: (request: CurrencyRequest, defaultValue?: Currency[]) => Promise<Currency[]> }
  currentUser: UserPromiseChain & { execute: (request: UserRequest, defaultValue?: User | null) => Promise<User | null> }
  products: { execute: (request: ProductRequest, defaultValue?: Product[]) => Promise<Product[]> }
  standards: { execute: (request: StandardsRequest, defaultValue?: Standards[]) => Promise<Standards[]> }
  ping: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

export interface QueryObservableChain {
  countries: { execute: (request: CountryRequest, defaultValue?: Country[]) => Observable<Country[]> }
  currencies: { execute: (request: CurrencyRequest, defaultValue?: Currency[]) => Observable<Currency[]> }
  currentUser: UserObservableChain & {
    execute: (request: UserRequest, defaultValue?: User | null) => Observable<User | null>
  }
  products: { execute: (request: ProductRequest, defaultValue?: Product[]) => Observable<Product[]> }
  standards: { execute: (request: StandardsRequest, defaultValue?: Standards[]) => Observable<Standards[]> }
  ping: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface CountryPromiseChain {
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  code: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

export interface CountryObservableChain {
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  code: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface CurrencyPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  code: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  sign: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  conversionRate: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  conversionRateDate: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  standardsregionSet: {
    execute: (request: StandardsRegionRequest, defaultValue?: StandardsRegion[]) => Promise<StandardsRegion[]>
  }
}

export interface CurrencyObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  code: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  sign: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  conversionRate: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  conversionRateDate: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  standardsregionSet: {
    execute: (request: StandardsRegionRequest, defaultValue?: StandardsRegion[]) => Observable<StandardsRegion[]>
  }
}

export interface StandardsRegionPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  currency: CurrencyPromiseChain & { execute: (request: CurrencyRequest, defaultValue?: Currency) => Promise<Currency> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  units: {
    execute: (
      request?: boolean | number,
      defaultValue?: MainStandardsRegionUnitsChoices,
    ) => Promise<MainStandardsRegionUnitsChoices>
  }
  standards: StandardsPromiseChain & {
    execute: (request: StandardsRequest, defaultValue?: Standards | null) => Promise<Standards | null>
  }
}

export interface StandardsRegionObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  currency: CurrencyObservableChain & {
    execute: (request: CurrencyRequest, defaultValue?: Currency) => Observable<Currency>
  }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  units: {
    execute: (
      request?: boolean | number,
      defaultValue?: MainStandardsRegionUnitsChoices,
    ) => Observable<MainStandardsRegionUnitsChoices>
  }
  standards: StandardsObservableChain & {
    execute: (request: StandardsRequest, defaultValue?: Standards | null) => Observable<Standards | null>
  }
}

export interface StandardsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  region: StandardsRegionPromiseChain & {
    execute: (request: StandardsRegionRequest, defaultValue?: StandardsRegion) => Promise<StandardsRegion>
  }
  feedingRates: FeedingRatesPromiseChain & {
    execute: (request: FeedingRatesRequest, defaultValue?: FeedingRates) => Promise<FeedingRates>
  }
  fmmoPricing: FmmoPricingPromiseChain & {
    execute: (request: FmmoPricingRequest, defaultValue?: FmmoPricing) => Promise<FmmoPricing>
  }
  milkPerformance: MilkPerformancePromiseChain & {
    execute: (request: MilkPerformanceRequest, defaultValue?: MilkPerformance) => Promise<MilkPerformance>
  }
  milkPerformanceDiff: MilkPerformancePromiseChain & {
    execute: (request: MilkPerformanceRequest, defaultValue?: MilkPerformance) => Promise<MilkPerformance>
  }
  reproductivePerformance: ReproductivePerformancePromiseChain & {
    execute: (
      request: ReproductivePerformanceRequest,
      defaultValue?: ReproductivePerformance,
    ) => Promise<ReproductivePerformance>
  }
  economicPerformance: EconomicPerformancePromiseChain & {
    execute: (request: EconomicPerformanceRequest, defaultValue?: EconomicPerformance) => Promise<EconomicPerformance>
  }
  mastitisClinicalPerformance: DiseasePerformancePromiseChain & {
    execute: (request: DiseasePerformanceRequest, defaultValue?: DiseasePerformance) => Promise<DiseasePerformance>
  }
  ketosisClinicalPerformance: DiseasePerformancePromiseChain & {
    execute: (request: DiseasePerformanceRequest, defaultValue?: DiseasePerformance) => Promise<DiseasePerformance>
  }
  ketosisSubclinicalPerformance: DiseasePerformancePromiseChain & {
    execute: (request: DiseasePerformanceRequest, defaultValue?: DiseasePerformance) => Promise<DiseasePerformance>
  }
  metritisPerformance: DiseasePerformancePromiseChain & {
    execute: (request: DiseasePerformanceRequest, defaultValue?: DiseasePerformance) => Promise<DiseasePerformance>
  }
}

export interface StandardsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  region: StandardsRegionObservableChain & {
    execute: (request: StandardsRegionRequest, defaultValue?: StandardsRegion) => Observable<StandardsRegion>
  }
  feedingRates: FeedingRatesObservableChain & {
    execute: (request: FeedingRatesRequest, defaultValue?: FeedingRates) => Observable<FeedingRates>
  }
  fmmoPricing: FmmoPricingObservableChain & {
    execute: (request: FmmoPricingRequest, defaultValue?: FmmoPricing) => Observable<FmmoPricing>
  }
  milkPerformance: MilkPerformanceObservableChain & {
    execute: (request: MilkPerformanceRequest, defaultValue?: MilkPerformance) => Observable<MilkPerformance>
  }
  milkPerformanceDiff: MilkPerformanceObservableChain & {
    execute: (request: MilkPerformanceRequest, defaultValue?: MilkPerformance) => Observable<MilkPerformance>
  }
  reproductivePerformance: ReproductivePerformanceObservableChain & {
    execute: (
      request: ReproductivePerformanceRequest,
      defaultValue?: ReproductivePerformance,
    ) => Observable<ReproductivePerformance>
  }
  economicPerformance: EconomicPerformanceObservableChain & {
    execute: (request: EconomicPerformanceRequest, defaultValue?: EconomicPerformance) => Observable<EconomicPerformance>
  }
  mastitisClinicalPerformance: DiseasePerformanceObservableChain & {
    execute: (request: DiseasePerformanceRequest, defaultValue?: DiseasePerformance) => Observable<DiseasePerformance>
  }
  ketosisClinicalPerformance: DiseasePerformanceObservableChain & {
    execute: (request: DiseasePerformanceRequest, defaultValue?: DiseasePerformance) => Observable<DiseasePerformance>
  }
  ketosisSubclinicalPerformance: DiseasePerformanceObservableChain & {
    execute: (request: DiseasePerformanceRequest, defaultValue?: DiseasePerformance) => Observable<DiseasePerformance>
  }
  metritisPerformance: DiseasePerformanceObservableChain & {
    execute: (request: DiseasePerformanceRequest, defaultValue?: DiseasePerformance) => Observable<DiseasePerformance>
  }
}

export interface FeedingRatesPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  herdComposition: ConstantsPerPhasePromiseChain & {
    execute: (request: ConstantsPerPhaseRequest, defaultValue?: ConstantsPerPhase) => Promise<ConstantsPerPhase>
  }
  dmi: ConstantsPerPhasePromiseChain & {
    execute: (request: ConstantsPerPhaseRequest, defaultValue?: ConstantsPerPhase) => Promise<ConstantsPerPhase>
  }
  currentFeedCosts: ConstantsPerPhasePromiseChain & {
    execute: (request: ConstantsPerPhaseRequest, defaultValue?: ConstantsPerPhase) => Promise<ConstantsPerPhase>
  }
  standards: StandardsPromiseChain & {
    execute: (request: StandardsRequest, defaultValue?: Standards | null) => Promise<Standards | null>
  }
  productFeedingRates: {
    execute: (request: ProductFeedingRateRequest, defaultValue?: ProductFeedingRate[]) => Promise<ProductFeedingRate[]>
  }
}

export interface FeedingRatesObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  herdComposition: ConstantsPerPhaseObservableChain & {
    execute: (request: ConstantsPerPhaseRequest, defaultValue?: ConstantsPerPhase) => Observable<ConstantsPerPhase>
  }
  dmi: ConstantsPerPhaseObservableChain & {
    execute: (request: ConstantsPerPhaseRequest, defaultValue?: ConstantsPerPhase) => Observable<ConstantsPerPhase>
  }
  currentFeedCosts: ConstantsPerPhaseObservableChain & {
    execute: (request: ConstantsPerPhaseRequest, defaultValue?: ConstantsPerPhase) => Observable<ConstantsPerPhase>
  }
  standards: StandardsObservableChain & {
    execute: (request: StandardsRequest, defaultValue?: Standards | null) => Observable<Standards | null>
  }
  productFeedingRates: {
    execute: (request: ProductFeedingRateRequest, defaultValue?: ProductFeedingRate[]) => Observable<ProductFeedingRate[]>
  }
}

export interface ConstantsPerPhasePromiseChain {
  farOff: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  closeUp: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  fresh: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  earlyMidLact: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  lateLact: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
}

export interface ConstantsPerPhaseObservableChain {
  farOff: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  closeUp: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  fresh: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  earlyMidLact: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  lateLact: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
}

export interface ProductFeedingRatePromiseChain {
  farOff: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  closeUp: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  fresh: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  earlyMidLact: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  lateLact: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  constantsperphasePtr: ConstantsPerPhasePromiseChain & {
    execute: (request: ConstantsPerPhaseRequest, defaultValue?: ConstantsPerPhase) => Promise<ConstantsPerPhase>
  }
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  feedingRates: FeedingRatesPromiseChain & {
    execute: (request: FeedingRatesRequest, defaultValue?: FeedingRates) => Promise<FeedingRates>
  }
  product: ProductPromiseChain & { execute: (request: ProductRequest, defaultValue?: Product) => Promise<Product> }
  herdComposition: FeedingRatesPromiseChain & {
    execute: (request: FeedingRatesRequest, defaultValue?: FeedingRates | null) => Promise<FeedingRates | null>
  }
  dmi: FeedingRatesPromiseChain & {
    execute: (request: FeedingRatesRequest, defaultValue?: FeedingRates | null) => Promise<FeedingRates | null>
  }
  currentFeedCosts: FeedingRatesPromiseChain & {
    execute: (request: FeedingRatesRequest, defaultValue?: FeedingRates | null) => Promise<FeedingRates | null>
  }
  productfeedingrate: ProductFeedingRatePromiseChain & {
    execute: (
      request: ProductFeedingRateRequest,
      defaultValue?: ProductFeedingRate | null,
    ) => Promise<ProductFeedingRate | null>
  }
}

export interface ProductFeedingRateObservableChain {
  farOff: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  closeUp: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  fresh: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  earlyMidLact: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  lateLact: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  constantsperphasePtr: ConstantsPerPhaseObservableChain & {
    execute: (request: ConstantsPerPhaseRequest, defaultValue?: ConstantsPerPhase) => Observable<ConstantsPerPhase>
  }
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  feedingRates: FeedingRatesObservableChain & {
    execute: (request: FeedingRatesRequest, defaultValue?: FeedingRates) => Observable<FeedingRates>
  }
  product: ProductObservableChain & { execute: (request: ProductRequest, defaultValue?: Product) => Observable<Product> }
  herdComposition: FeedingRatesObservableChain & {
    execute: (request: FeedingRatesRequest, defaultValue?: FeedingRates | null) => Observable<FeedingRates | null>
  }
  dmi: FeedingRatesObservableChain & {
    execute: (request: FeedingRatesRequest, defaultValue?: FeedingRates | null) => Observable<FeedingRates | null>
  }
  currentFeedCosts: FeedingRatesObservableChain & {
    execute: (request: FeedingRatesRequest, defaultValue?: FeedingRates | null) => Observable<FeedingRates | null>
  }
  productfeedingrate: ProductFeedingRateObservableChain & {
    execute: (
      request: ProductFeedingRateRequest,
      defaultValue?: ProductFeedingRate | null,
    ) => Observable<ProductFeedingRate | null>
  }
}

export interface ProductPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  price: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
}

export interface ProductObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  price: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
}

export interface FmmoPricingPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  milk: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  fat: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  protein: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  otherSolids: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  premium: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  standards: StandardsPromiseChain & {
    execute: (request: StandardsRequest, defaultValue?: Standards | null) => Promise<Standards | null>
  }
}

export interface FmmoPricingObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  milk: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  fat: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  protein: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  otherSolids: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  premium: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  standards: StandardsObservableChain & {
    execute: (request: StandardsRequest, defaultValue?: Standards | null) => Observable<Standards | null>
  }
}

export interface MilkPerformancePromiseChain {
  milk: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  fat: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  protein: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  otherSolids: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
}

export interface MilkPerformanceObservableChain {
  milk: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  fat: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  protein: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  otherSolids: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
}

export interface ReproductivePerformancePromiseChain {
  lastMonthToBreed: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  minimumMilkToBreed: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  pregnancyRate: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  pregnancyRateDiff: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  pregnancyLoss: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  pregnancyLossDiff: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
}

export interface ReproductivePerformanceObservableChain {
  lastMonthToBreed: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  minimumMilkToBreed: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  pregnancyRate: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  pregnancyRateDiff: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  pregnancyLoss: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  pregnancyLossDiff: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
}

export interface EconomicPerformancePromiseChain {
  reproductionCost: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  replacementCost: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  cullValue: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  calfValue: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  cullingRate: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
}

export interface EconomicPerformanceObservableChain {
  reproductionCost: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  replacementCost: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  cullValue: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  calfValue: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  cullingRate: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
}

export interface DiseasePerformancePromiseChain {
  regularIncidence: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  reducedIncidence: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  usdCase: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  reductionPercent: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
}

export interface DiseasePerformanceObservableChain {
  regularIncidence: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  reducedIncidence: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  usdCase: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  reductionPercent: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
}

export interface UserPromiseChain {
  email: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

export interface UserObservableChain {
  email: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface MutationPromiseChain {
  calculate: (args: {
    input: CalculationInput
  }) => CalculatePromiseChain & { execute: (request: CalculateRequest, defaultValue?: Calculate) => Promise<Calculate> }
  login: (args: {
    email: String
    password: String
  }) => LoginPromiseChain & { execute: (request: LoginRequest, defaultValue?: Login | null) => Promise<Login | null> }
  register: (args: {
    company: String
    country: String
    email: String
    name: String
    password: String
    password2: String
  }) => RegisterPromiseChain & {
    execute: (request: RegisterRequest, defaultValue?: Register | null) => Promise<Register | null>
  }
  ping: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

export interface MutationObservableChain {
  calculate: (args: {
    input: CalculationInput
  }) => CalculateObservableChain & {
    execute: (request: CalculateRequest, defaultValue?: Calculate) => Observable<Calculate>
  }
  login: (args: {
    email: String
    password: String
  }) => LoginObservableChain & { execute: (request: LoginRequest, defaultValue?: Login | null) => Observable<Login | null> }
  register: (args: {
    company: String
    country: String
    email: String
    name: String
    password: String
    password2: String
  }) => RegisterObservableChain & {
    execute: (request: RegisterRequest, defaultValue?: Register | null) => Observable<Register | null>
  }
  ping: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface CalculatePromiseChain {
  currency: CurrencyPromiseChain & { execute: (request: CurrencyRequest, defaultValue?: Currency) => Promise<Currency> }
  product: ProductPromiseChain & { execute: (request: ProductRequest, defaultValue?: Product) => Promise<Product> }
  productPriceConverted: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  iofcShortAndLongTerm: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  iofcShortTerm: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  resultsUnits: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  roiShortAndLongTerm: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  roiShortTerm: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  weightUnits: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  investment: CalculationsInvestmentPromiseChain & {
    execute: (
      request: CalculationsInvestmentRequest,
      defaultValue?: CalculationsInvestment,
    ) => Promise<CalculationsInvestment>
  }
  shortTermReturns: CalculationsShortTermReturnsPromiseChain & {
    execute: (
      request: CalculationsShortTermReturnsRequest,
      defaultValue?: CalculationsShortTermReturns,
    ) => Promise<CalculationsShortTermReturns>
  }
  longTermReturnsReproduction: CalculationsLongTermReturnsReproductionPromiseChain & {
    execute: (
      request: CalculationsLongTermReturnsReproductionRequest,
      defaultValue?: CalculationsLongTermReturnsReproduction,
    ) => Promise<CalculationsLongTermReturnsReproduction>
  }
  longTermReturnsHealth: CalculationsLongTermReturnsHealthPromiseChain & {
    execute: (
      request: CalculationsLongTermReturnsHealthRequest,
      defaultValue?: CalculationsLongTermReturnsHealth,
    ) => Promise<CalculationsLongTermReturnsHealth>
  }
}

export interface CalculateObservableChain {
  currency: CurrencyObservableChain & {
    execute: (request: CurrencyRequest, defaultValue?: Currency) => Observable<Currency>
  }
  product: ProductObservableChain & { execute: (request: ProductRequest, defaultValue?: Product) => Observable<Product> }
  productPriceConverted: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  iofcShortAndLongTerm: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  iofcShortTerm: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  resultsUnits: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  roiShortAndLongTerm: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  roiShortTerm: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  weightUnits: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  investment: CalculationsInvestmentObservableChain & {
    execute: (
      request: CalculationsInvestmentRequest,
      defaultValue?: CalculationsInvestment,
    ) => Observable<CalculationsInvestment>
  }
  shortTermReturns: CalculationsShortTermReturnsObservableChain & {
    execute: (
      request: CalculationsShortTermReturnsRequest,
      defaultValue?: CalculationsShortTermReturns,
    ) => Observable<CalculationsShortTermReturns>
  }
  longTermReturnsReproduction: CalculationsLongTermReturnsReproductionObservableChain & {
    execute: (
      request: CalculationsLongTermReturnsReproductionRequest,
      defaultValue?: CalculationsLongTermReturnsReproduction,
    ) => Observable<CalculationsLongTermReturnsReproduction>
  }
  longTermReturnsHealth: CalculationsLongTermReturnsHealthObservableChain & {
    execute: (
      request: CalculationsLongTermReturnsHealthRequest,
      defaultValue?: CalculationsLongTermReturnsHealth,
    ) => Observable<CalculationsLongTermReturnsHealth>
  }
}

export interface CalculationsInvestmentPromiseChain {
  cowsAmount: ValuesPerPhasePromiseChain & {
    execute: (request: ValuesPerPhaseRequest, defaultValue?: ValuesPerPhase) => Promise<ValuesPerPhase>
  }
  productPricePerCowDay: ValuesPerPhasePromiseChain & {
    execute: (request: ValuesPerPhaseRequest, defaultValue?: ValuesPerPhase) => Promise<ValuesPerPhase>
  }
  dmiWithProduct: ValuesPerPhasePromiseChain & {
    execute: (request: ValuesPerPhaseRequest, defaultValue?: ValuesPerPhase) => Promise<ValuesPerPhase>
  }
  dmCostWithProduct: ValuesPerPhasePromiseChain & {
    execute: (request: ValuesPerPhaseRequest, defaultValue?: ValuesPerPhase) => Promise<ValuesPerPhase>
  }
  feedCostPerCowDay: ValuesPerPhasePromiseChain & {
    execute: (request: ValuesPerPhaseRequest, defaultValue?: ValuesPerPhase) => Promise<ValuesPerPhase>
  }
  feedCostPerCowDayWithProduct: ValuesPerPhasePromiseChain & {
    execute: (request: ValuesPerPhaseRequest, defaultValue?: ValuesPerPhase) => Promise<ValuesPerPhase>
  }
  totalFeedCostPerCowYear: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  totalFeedCostPerCowYearWithProduct: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  totalFeedCostPerCowYearDifference: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  totalFeedCostDifference: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
}

export interface CalculationsInvestmentObservableChain {
  cowsAmount: ValuesPerPhaseObservableChain & {
    execute: (request: ValuesPerPhaseRequest, defaultValue?: ValuesPerPhase) => Observable<ValuesPerPhase>
  }
  productPricePerCowDay: ValuesPerPhaseObservableChain & {
    execute: (request: ValuesPerPhaseRequest, defaultValue?: ValuesPerPhase) => Observable<ValuesPerPhase>
  }
  dmiWithProduct: ValuesPerPhaseObservableChain & {
    execute: (request: ValuesPerPhaseRequest, defaultValue?: ValuesPerPhase) => Observable<ValuesPerPhase>
  }
  dmCostWithProduct: ValuesPerPhaseObservableChain & {
    execute: (request: ValuesPerPhaseRequest, defaultValue?: ValuesPerPhase) => Observable<ValuesPerPhase>
  }
  feedCostPerCowDay: ValuesPerPhaseObservableChain & {
    execute: (request: ValuesPerPhaseRequest, defaultValue?: ValuesPerPhase) => Observable<ValuesPerPhase>
  }
  feedCostPerCowDayWithProduct: ValuesPerPhaseObservableChain & {
    execute: (request: ValuesPerPhaseRequest, defaultValue?: ValuesPerPhase) => Observable<ValuesPerPhase>
  }
  totalFeedCostPerCowYear: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  totalFeedCostPerCowYearWithProduct: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  totalFeedCostPerCowYearDifference: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  totalFeedCostDifference: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
}

export interface ValuesPerPhasePromiseChain {
  farOff: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  closeUp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  fresh: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  earlyMidLact: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  lateLact: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface ValuesPerPhaseObservableChain {
  farOff: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  closeUp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  fresh: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  earlyMidLact: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  lateLact: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface CalculationsShortTermReturnsPromiseChain {
  milkPerCowDayWithProduct: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  fatPercent: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  proteinPercent: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  otherSolidsPercent: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  fatPerCowDay: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
  proteinPerCowDay: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
  otherSolidsPerCowDay: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
  ecmPerCowDay: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
  impactCwtCost: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
  impactPerCowDayCost: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
  impactPerCowYearCost: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
  totalImpactCost: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
}

export interface CalculationsShortTermReturnsObservableChain {
  milkPerCowDayWithProduct: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  fatPercent: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  proteinPercent: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  otherSolidsPercent: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  fatPerCowDay: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
  proteinPerCowDay: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
  otherSolidsPerCowDay: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
  ecmPerCowDay: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
  impactCwtCost: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
  impactPerCowDayCost: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
  impactPerCowYearCost: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
  totalImpactCost: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
}

export interface DifferencePromiseChain {
  current: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  withProduct: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  difference: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

export interface DifferenceObservableChain {
  current: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  withProduct: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  difference: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

export interface CalculationsLongTermReturnsReproductionPromiseChain {
  rollingHerdAvg: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  pregnancyRateWithProduct: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  pregnancyLossWithProduct: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  calfSales: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
  mortalityCost: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
  reproductiveCost: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
  reproductiveCullingCost: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
  nonReproductiveCullingCost: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
  totalReturns: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
  totalReturnsFinal: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
}

export interface CalculationsLongTermReturnsReproductionObservableChain {
  rollingHerdAvg: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  pregnancyRateWithProduct: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  pregnancyLossWithProduct: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  calfSales: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
  mortalityCost: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
  reproductiveCost: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
  reproductiveCullingCost: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
  nonReproductiveCullingCost: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
  totalReturns: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
  totalReturnsFinal: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
}

export interface CalculationsLongTermReturnsHealthPromiseChain {
  mastitis: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
  ketosisClinical: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
  ketosisSubclinical: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
  metritis: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
  totalSavings: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
  totalSavingsFinal: DifferencePromiseChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Promise<Difference>
  }
}

export interface CalculationsLongTermReturnsHealthObservableChain {
  mastitis: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
  ketosisClinical: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
  ketosisSubclinical: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
  metritis: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
  totalSavings: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
  totalSavingsFinal: DifferenceObservableChain & {
    execute: (request: DifferenceRequest, defaultValue?: Difference) => Observable<Difference>
  }
}

export interface LoginPromiseChain {
  success: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
}

export interface LoginObservableChain {
  success: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
}

export interface RegisterPromiseChain {
  success: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
}

export interface RegisterObservableChain {
  success: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
}
