import { darken, lighten } from '@mui/system/colorManipulator'


export function volumeSx(isPdf: boolean, baseColor?: string, textColor?: string) {
  const backgroundColor = baseColor || '#BFBFBF'
  // const gradientLightColor = lighten(backgroundColor, 0.15)
  const darkColor = darken(backgroundColor, 0.5)
  const borderColor = darken(backgroundColor, 0.5)

  return {
    backgroundColor: backgroundColor,
    color: textColor || 'black',
    ...(isPdf
      ? {pt: '3px'}
      : {
        boxShadow: `rgba(255, 255, 255, 0.7) 1px 2px 1px 0 inset, rgba(0, 0, 0, 0.3) -1px -2px 1px 0 inset, ${borderColor} 0px 0px 0px 1px`,
      }
    ),
    // backgroundImage: `linear-gradient(180deg, ${gradientLightColor} 70%, ${gradientDarkColor} 100%)`,
    '&:hover': {
      color: textColor || 'black',
      backgroundColor: backgroundColor,
      filter: 'brightness(1.05)',
    },
    '&:active, &.Mui-selected': {
      color: textColor || (isPdf ? 'white' : 'black'),
      ...(isPdf
        ? {backgroundColor: darkColor,}
        : {
          backgroundColor: backgroundColor,
          boxShadow: `rgba(255, 255, 255, 0.7) -1px -2px 1px 0 inset, rgba(0, 0, 0, 0.3) 1px 2px 1px 0 inset, ${borderColor} 0px 0px 0px 1px`,
        }
      ),
      // backgroundImage: `linear-gradient(0deg, ${gradientLightColor} 70%, ${gradientDarkColor} 100%)`,
    },
    '&:active, &.Mui-selected:hover': {
      color: textColor || 'black',
      backgroundColor: backgroundColor,
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  }
}