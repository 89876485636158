import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

import { PrivateRoute } from 'components/PrivateRoute'

import { Index } from './pages/Index'
import { Login } from './pages/auth/Login'
import { Register } from './pages/auth/Register'
import { PageNotFound } from './pages/PageNotFound'


export const routes = {
  INDEX: '/',
  LOGIN: '/login',
  REGISTER: '/register'
}

export const router = createBrowserRouter([
  {
    path: routes.INDEX,
    element: <PrivateRoute component={<Index/>} />,
  },
  {
    path: routes.LOGIN,
    element: <Login />,
  },
  {
    path: routes.REGISTER,
    element: <Register />,
  },
  { path: '*', element: <PageNotFound /> },
])