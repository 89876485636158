import {useField, useFormikContext} from 'formik'
import * as mui from '@mui/material'
import React from 'react'

import {volumeSx} from 'utils/style'


export function ToggleButtons({
  buttonSx,
  colorLink,
  buttonColor,
  textColor,
  fullWidth,
  isPdf,
  name,
  optionsRows,
  ...otherProps
}: {
  buttonSx?: mui.SxProps<mui.Theme>,
  colorLink?: string,
  buttonColor?: string,
  textColor?: string,
  fullWidth?: boolean,
  isPdf: boolean,
  name: string,
  optionsRows: {label: string, value: string}[][]
} & mui.ToggleButtonGroupProps) {
  const { submitForm } = useFormikContext()
  const [field, , helpers] = useField(name)

  return (
    <mui.ToggleButtonGroup
      value={field.value}
      exclusive
      {...otherProps}
      onChange={async (event, newValue) => {
        if (newValue === null) return
        if (otherProps.onChange) await otherProps.onChange(event, newValue)
        helpers
          .setValue(newValue)
          .then(submitForm)
      }}
      sx={{
        display: 'flex',
        flexGrow: fullWidth ? 1 : undefined,
        flexWrap: 'wrap',
        mx: '-8px !important',
        '& .MuiToggleButtonGroup-grouped': {
          margin: .5,
          border: 0,
        },
        '& .MuiToggleButtonGroup-firstButton, & .MuiToggleButtonGroup-middleButton, & .MuiToggleButtonGroup-lastButton': {
          borderRadius: '6px',
        },
      }}
    >
      {optionsRows.map((options, i) => (
        <mui.Box key={i} sx={{
          position: 'relative',
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
          justifyContent: 'center',
        }}>
          <mui.Box sx={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            bottom: '10px',
            right: '10px',
            backgroundColor: colorLink || '#D5D5D5',
          }}/>
          {options.map(option =>
            <mui.ToggleButton
              key={option.value}
              sx={{
                flexGrow: fullWidth ? 1 : undefined,
                fontSize: '.7rem',
                px: 1,
                py: .5,
                textTransform: 'none',
                ...volumeSx(isPdf, buttonColor, textColor),
                ...buttonSx,
              }}
              value={option.value}
            >
              {option.label}
            </mui.ToggleButton>
          )}
        </mui.Box>
      ))}
    </mui.ToggleButtonGroup>
  )
}
