import * as mui from '@mui/material'
import {Field, Form, Formik} from 'formik'
import {Select, TextField} from 'formik-mui'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import React from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'

import {Link} from 'components/Link'
import {userContext} from 'context/user'
import {routes} from 'routes'
import {graphqlClient, useQuery} from 'utils/graphql'


const validationSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  company: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  password: Yup.string().required('Required').min(8, '8 characters minimum'),
  password2: Yup.string().required('Required').oneOf([Yup.ref('password')], 'Passwords must match'),
})
type valuesType = Yup.InferType<typeof validationSchema>

export function Register() {
  const [commonErrors, setCommonErrors] = React.useState<string[]>([])
  const [fieldErrors, setFieldErrors] = React.useState<{[k: string]: string}>({})

  const navigate = useNavigate()

  const user = React.useContext(userContext)
  const countries = useQuery(
    {countries: {__scalar: 1}},
    'countries'
  )

  function handleSubmit(
    values: valuesType,
    {setSubmitting}: {setSubmitting: (isSubmitting: boolean) => void}
  ) {
    setCommonErrors([])
    graphqlClient.chain.mutation
      .register(values)
      .execute({__scalar: 1})
      .then(() => navigate(routes.LOGIN+'?registered=true'))
      .catch(e => {
        if (e.errors[0].extensions) {
          setCommonErrors(e.errors[0].extensions.common_errors || [])
          setFieldErrors(e.errors[0].extensions.field_errors || {})
        } else {
          throw e
        }
      })
      .finally(() => setSubmitting(false))
  }

  if (user.data) return <Navigate to={routes.INDEX} />

  return (
    <mui.Container component="main" maxWidth="xs">
      <mui.Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <mui.Avatar sx={{m: 2, bgcolor: 'secondary.main'}}>
          <LockOutlinedIcon />
        </mui.Avatar>
        <mui.Typography component="h1" variant="h5">
          Request Access
        </mui.Typography>
        <Formik
          initialValues={{email: '', company: '', country: 'US', name: '', password: '', password2: ''}}
          onSubmit={handleSubmit}
          sx={{mt: 1}}
          validateOnBlur={true}
          validateOnChange={false}
          validationSchema={validationSchema}
        >
          {({submitForm, isSubmitting, touched, errors, isValid}) => (
            <Form style={{width: '100%'}}>
              <mui.Stack spacing={3} mt={3}>
                {commonErrors.map((error, i) => (
                  <mui.Alert key={i} severity="error">{error}</mui.Alert>
                ))}
                <Field
                  component={TextField}
                  error={touched.name && (!!errors.name || !!fieldErrors.name)}
                  helperText={touched.name && (errors.name || fieldErrors.name)}
                  InputProps={{fullWidth: true}}
                  label="Name"
                  name="name"
                />
                <Field
                  component={TextField}
                  error={touched.company && (!!errors.company || !!fieldErrors.company)}
                  helperText={touched.company && (errors.company || fieldErrors.company)}
                  InputProps={{fullWidth: true}}
                  label="Company"
                  name="company"
                />
                <Field
                  component={Select}
                  name="country"
                  error={touched.country && (!!errors.country || !!fieldErrors.country)}
                  helperText={touched.country && (errors.country || fieldErrors.country)}
                >
                  {(countries.data || [{code: 'US', name: 'United States of America'}]).map((country, i) => (
                    <mui.MenuItem key={i} value={country.code}>
                      {country.name}
                    </mui.MenuItem>
                  ))}
                </Field>
                <Field
                  component={TextField}
                  error={touched.email && (!!errors.email || !!fieldErrors.email)}
                  helperText={touched.email && (errors.email || fieldErrors.email)}
                  InputProps={{autoComplete: 'email', fullWidth: true}}
                  InputLabelProps={{shrink: true}}
                  label="Email address"
                  name="email"
                />
                <Field
                  component={TextField}
                  error={touched.password && (!!errors.password || !!fieldErrors.password)}
                  helperText={touched.password && (errors.password || fieldErrors.password)}
                  InputProps={{autoComplete: 'off', fullWidth: true, type: 'password'}}
                  label="Password"
                  name="password"
                />
                <Field
                  component={TextField}
                  error={touched.password2 && (!!errors.password2 || !!fieldErrors.password2)}
                  helperText={touched.password2 && (errors.password2 || fieldErrors.password2)}
                  InputProps={{autoComplete: 'off', fullWidth: true, type: 'password'}}
                  label="Confirm password"
                  name="password2"
                />
                <mui.Button
                  disabled={
                    isSubmitting ||
                    !isValid ||
                    Object.keys(fieldErrors).length > 0 ||
                    Object.keys(touched).length === 0
                }
                  fullWidth
                  onClick={submitForm}
                  sx={{mt: 3, mb: 2, py: 1.5}}
                  variant="contained"
                >
                  Request Access
                </mui.Button>
                <mui.Grid container>
                  <mui.Grid item>
                    <Link to={routes.LOGIN} variant="body2">
                      Already have an account? Sign In
                    </Link>
                  </mui.Grid>
                </mui.Grid>
              </mui.Stack>
            </Form>
          )}
        </Formik>
      </mui.Box>
    </mui.Container>
  )
}