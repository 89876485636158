import * as mui from '@mui/material'
import React from 'react'
import {useField, useFormikContext} from 'formik'


export function ToggleButton(props: {
  name: string,
  label: React.ReactNode,
  sx?: mui.SxProps<mui.Theme>
}) {
  const { submitForm } = useFormikContext()
  const [field, , helpers] = useField(props.name)

  return (
    <mui.ToggleButton
      value="check"
      onChange={() => helpers.setValue(!field.value).then(submitForm)}
      selected={field.value}
      sx={{
        borderRadius: '6px',
        border: 0,
        px: 3,
        py: 2,
        textTransform: 'none',
        '&.Mui-selected:not(:hover)': {},
        ...props.sx,
      }}
    >
      <mui.Box>{props.label}</mui.Box>
    </mui.ToggleButton>
  )
}
