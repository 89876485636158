import * as mui from '@mui/material'
import React from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'


export const Link = React.forwardRef<HTMLAnchorElement, mui.LinkProps & RouterLinkProps>(
  function Link(props, ref) {
    return <mui.Link component={RouterLink} ref={ref} {...props} />
  }
)
