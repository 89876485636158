import * as mui from '@mui/material'
import React from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'


export function TableSection(props: {
  colorGroupText: string
  colorGroupBg: string
  colorTableBg: string
  colorResultBg: string
  columns: (
    string | {
      content: string | React.ReactNode
      cellProps?: mui.TableCellProps
      tooltip?: string | React.ReactNode
    }
  )[][],
  data: (
    string | {
      borderBottom?: boolean
      borderTop?: boolean
      content: string | React.ReactNode
      cellProps?: mui.TableCellProps
      tooltip?: string | React.ReactNode
    }
  )[][],
  dataRowsCollapsible: number[]
  groups: {
    content: string | React.ReactNode
    firstRow: number
    titlePadding: string
  }[],
  isPdf: boolean,
  groupsColumn?: string | React.ReactNode
  result: {
    title: string
    tooltip?: string | React.ReactNode
    padding: string
    subtitle: string
    units: string
    value: string
  },
}) {
  const [collapsed, setCollapsed] = React.useState(true)

  const tooltipStyle = props.isPdf ? {} : {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      width: 0,
      height: 0,
      borderLeft: '5px solid transparent',
      borderTop: '5px solid #FCBC03',
    }
  } as any

  function getGroup(row: number) {
    const groupIndex = props.groups.findIndex(group => group.firstRow === row)
    if (groupIndex === -1) return []
    const lastRow = props.groups[groupIndex+1]?.firstRow || props.data.length + 1
    return [{
      index: groupIndex,
      content: props.groups[groupIndex].content,
      rowSpan: lastRow - row,
      titlePadding: props.groups[groupIndex].titlePadding,
    }]
  }

  function firstNonCollapsibleRow() {
    let i = 0
    while (props.dataRowsCollapsible.includes(i)) i++
    return i
  }

  function nextNonCollapsibleRow(row: number) {
    let i = row + 1
    while (props.dataRowsCollapsible.includes(i)) i++
    return i
  }

  return (
    <mui.Grid mb={1}>
      <mui.Table
        size="small"
        style={{whiteSpace: 'pre-wrap'}}
        sx={{
          borderCollapse: 'separate',
          position: 'relative',
          whiteSpace: 'nowrap',
          '& .MuiTableCell-root': {
            py: 0,
          },
          '& td, & th': {
            px: 1,
            whiteSpace: 'nowrap',
          },
          '& th': {pt: '0!important'},
        }}
      >
        <mui.TableHead>
          {props.columns.map((columnRow, i) => (
            <mui.TableRow key={i}>
              <mui.TableCell sx={{width: '175px'}}/>
              {columnRow.map((column, j) =>
                typeof column === 'string'
                  ? <mui.TableCell key={j} sx={{textAlign: 'center', width: j === 0 ? '140px' : null}}>
                      <b>{column}</b>
                    </mui.TableCell>
                  : <mui.TableCell
                      key={j}
                      {...(column.cellProps || {})}
                      sx={{
                        textAlign: 'center',
                        ...(!!column.tooltip && tooltipStyle),
                        ...(column.cellProps?.sx || {}),
                      }}
                    >
                      {!!column.tooltip
                        ? <mui.Tooltip title={column.tooltip} placement="right-start">
                            <mui.Box  sx={{width: '100%'}}>
                              {typeof column.content === 'string'
                                ? <b>{column.content}</b>
                                : <span>{column.content}</span>
                              }
                            </mui.Box>
                          </mui.Tooltip>
                        : typeof column.content === 'string'
                          ? <b>{column.content}</b>
                          : <span>{column.content}</span>
                      }
                    </mui.TableCell>
              )}
              {i === props.columns.length-1 &&
                <mui.TableCell sx={{textAlign: 'center'}}>
                  <b>{props.result.title}</b>
                </mui.TableCell>
              }
            </mui.TableRow>
          ))}
        </mui.TableHead>
        <mui.TableBody>
          {props.data.map((row, rowIndex) => (
            <mui.TableRow key={rowIndex}>
              {/* Groups at the left (must align with table rows) */}
              {getGroup(rowIndex).map((group, i) => group && (
                <mui.TableCell
                  key={i}
                  rowSpan={group.rowSpan}
                  sx={{
                    backgroundColor: props.colorGroupBg,
                    borderLeft: '3px solid #000',
                    borderRight: '5px solid #fff',
                    position: 'relative',
                    px: 0,
                    textAlign: 'center',
                    verticalAlign: 'top',
                  }}
                >
                  {!!group.index && props.groups.length > 1 && <>
                    <mui.Box sx={{
                      backgroundColor: props.colorGroupBg,
                      height: '20px',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                    }}/>
                    <mui.Box sx={{
                      backgroundColor: props.colorGroupBg,
                      height: '20px',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      zIndex: 1,
                    }}/>
                  </>}
                  <mui.Typography
                    variant="h4"
                    color={props.colorGroupText}
                    sx={{
                      fontFamily: 'Exo, Roboto, Helvetica, Arial, sans-serif',
                      position: 'relative', 
                      pt: group.titlePadding,
                      zIndex: 2,
                    }}
                  >
                    {group.content}
                  </mui.Typography>
                  <mui.Box sx={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                    <mui.IconButton
                      onClick={() => setCollapsed(!collapsed)}
                      sx={{color: props.colorGroupText, position: 'absolute', bottom: 0}}
                    >
                      {!collapsed ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    </mui.IconButton>
                  </mui.Box>
                </mui.TableCell>
              ))}
              {/* Table data */}
              {(!collapsed || !props.dataRowsCollapsible.includes(rowIndex)) && row.map((cell, i) =>
                typeof cell === 'string'
                  ? <mui.TableCell
                    key={i}
                    sx={{
                      backgroundColor: props.colorTableBg,
                      textAlign: i === 0 ? 'left' : 'center',
                    }}
                    >
                      <span dangerouslySetInnerHTML={{ __html: cell }}/>
                    </mui.TableCell>
                  : <mui.TableCell
                    key={i}
                    {...(cell.cellProps || {})}
                      sx={{
                        backgroundColor: props.colorTableBg,
                        borderBottom: cell.borderBottom && (
                            !collapsed || !(props.data[nextNonCollapsibleRow(rowIndex)][i] as any).borderTop
                        )
                          ? '3px solid white'
                          : undefined,
                        borderTop: cell.borderTop ? '3px solid white' : undefined,
                        textAlign: i === 0 ? 'left' : 'center',
                        ...(!!cell.tooltip && tooltipStyle),
                        ...(cell.cellProps?.sx || {}),
                      }}
                    >
                    {!!cell.tooltip
                      ? <mui.Tooltip title={cell.tooltip} placement="right-start">
                          {typeof cell.content === 'string'
                            ? <div dangerouslySetInnerHTML={{__html: cell.content}}/>
                            : <div>{cell.content!}</div>
                          }
                        </mui.Tooltip>
                      : typeof cell.content === 'string'
                        ? <div dangerouslySetInnerHTML={{__html: cell.content}}/>
                        : <div>{cell.content!}</div>
                    }
                    </mui.TableCell>
              )}
              {((!collapsed && rowIndex === 0) || (collapsed && rowIndex === firstNonCollapsibleRow())) &&
                // Result column
                <mui.TableCell
                  sx={{
                    backgroundColor: props.colorResultBg,
                    borderLeft: '5px solid #fff',
                    borderRight: '3px solid #000',
                    position: 'relative',
                    px: 0,
                    verticalAlign: 'top',
                    width: '180px',
                  }}
                  rowSpan={props.data.length}
                >
                  <mui.Box sx={{alignItems: 'center', color: 'white', display: 'flex', flexDirection: 'column'}}>
                    <mui.Typography sx={{fontSize: '16px', pt: .5}}>
                      {props.result.subtitle}
                    </mui.Typography>
                  </mui.Box>
                  <mui.Box sx={{
                    color: '#fff',
                    flexDirection: 'column',
                    pt: props.result.padding,
                    textAlign: 'center',
                  }}>
                    {props.result.tooltip
                      ? <mui.Tooltip title={props.result.tooltip} placement="right-start" sx={tooltipStyle}>
                          <mui.Typography variant="h4">
                            {props.result.value}
                          </mui.Typography>
                        </mui.Tooltip>
                      : <mui.Typography variant="h4">
                          {props.result.value}
                        </mui.Typography>
                    }
                    <mui.Typography sx={{fontSize: '16px'}}>
                      {props.result.units}
                    </mui.Typography>
                  </mui.Box>
                </mui.TableCell>
              }
            </mui.TableRow>
          ))}
        </mui.TableBody>
      </mui.Table>
    </mui.Grid>
  )
}