import React from 'react'
import { Navigate } from 'react-router-dom'

import { userContext } from 'context/user'
import { routes } from 'routes'


export function PrivateRoute({component}: {component: React.ReactNode}) {
  const user = React.useContext(userContext)

  if (user.spinner) return user.spinner
  if (!user.data) return <Navigate to={routes.LOGIN} />

  return component
}
