import * as mui from '@mui/material'
import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { userContext, useUserContext } from 'context/user'

import { theme } from './theme'
import { router } from './routes'


Sentry.init({
  dsn: '',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^\//],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  environment: window.GLOBAL_CONFIG.ENV,
})

function RootComponent() {
  const user = useUserContext()

  return (
    <React.StrictMode>
      <mui.CssBaseline />
      <mui.ThemeProvider theme={theme}>
        <userContext.Provider value={user}>
          <RouterProvider router={router} />
        </userContext.Provider>
      </mui.ThemeProvider>
    </React.StrictMode>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(<RootComponent />)
