import * as mui from '@mui/material'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'
import {Calculate} from 'types/graphql/schema'


export function Chart(props: {calculationResult: Calculate}) {
  const v = props.calculationResult
  const barChartConfig = {
    accessibility: {enabled: false},
    chart: {
      type: 'column',
      height: 194,
      backgroundColor: 'transparent',
      borderRadius: 5,
    },
    credits: {enabled: false},
    legend: {enabled: false},
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        groupPadding: 0,
        dataLabels: {
          align: 'center',
          enabled: true,
          inside: true,
          verticalAlign: 'middle',
          format: '{point.name}',
          style: {
            color: '#FFFFFF',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontWeight: 700,
            textOutline: 'none',
          },
        }
      },
      series: {
        stacking: 'normal',
      },
    },
    series: [
      {color: 'rgba(230, 235, 153, .87)', data: [{
        name: v.longTermReturnsHealth.totalSavings.difference ? 'Health' : '',
        y: v.longTermReturnsHealth.totalSavings.difference,
      }], dataLabels: {style: {color: '#AFB709'}}},
      {color: 'rgb(193, 204, 0, .87)', data: [{
        name: v.longTermReturnsReproduction.totalReturns.difference ? 'Repro' : '',
        y: v.longTermReturnsReproduction.totalReturns.difference,
      }]},
      {color: 'rgb(194, 84, 108, .87)', data: [{
        name: v.shortTermReturns.impactPerCowYearCost.difference ? 'Milk' : '',
        y: v.shortTermReturns.impactPerCowYearCost.difference,
      }]},
      {color: 'rgb(103, 201, 221, .87)', data: [{
        name: v.investment.totalFeedCostPerCowYearDifference ? 'Investment' : '',
        y: v.investment.totalFeedCostPerCowYearDifference,
      }]},
    ],
    title: { text: '' },
    tooltip: {
      formatter: function(this: Highcharts.TooltipFormatterContextObject) {
        return Highcharts.numberFormat(this.y as number, 2)
      }
    },
    xAxis: {
      labels: {enabled: false},
      lineColor: 'none',
      tickWidth: 0,
    },
    yAxis: {
      gridLineDashStyle: 'Dash',
      gridLineColor: 'black',
      labels: {
        style: {
          opacity: .55,
        }
      },
      startOnTick: false,
      tickInterval: 50,
      title: {
        enabled: false,
      },
    },
  }
  return <mui.Box sx={{
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center',
    pt: '54px',
    width: '100%',
  }}>
    <mui.Typography variant="caption" sx={{fontWeight: 'bold', textAlign: 'center', mb: .5}}>
      Investment vs. Gross Returns
    </mui.Typography>
    <mui.Box sx={{
      display: 'flex',
      backgroundColor: '#F5F5F5',
      borderRadius: '20px',
      justifyContent: 'center',
      width: '100%',
    }}>
      <mui.Typography variant="body2" sx={{
        opacity: .5,
        alignSelf: 'center',
      }}>
        $/cow/yr
      </mui.Typography>
      <mui.Box sx={{width: '70%', py: 1, position: 'relative'}}>
        <HighchartsReact highcharts={Highcharts} options={barChartConfig}/>
      </mui.Box>
    </mui.Box>
  </mui.Box>
}