import {useFormikContext} from 'formik'
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
import * as mui from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import React from 'react'

import {
  Currency,
  ProductFeedingRate,
  Product,
  ResultsUnitsAmount,
  ResultsUnitsPeriod,
  Units,
} from 'types/graphql/schema'
import {volumeSx} from 'utils/style'
import {convertCurrency} from 'utils/units'
import {renderValues} from 'utils/values'

import {valuesType} from '../../Index'
import {NumberField} from './NumberField'
import {ToggleButton} from './ToggleButton'
import {ToggleButtons} from './ToggleButtons'

import pdfIcon from '../assets/pdf.png'
import emailIcon from '../assets/email.png'


export type NotesState = {
  selectedDate: Date|null
  setSelectedDate: (date: Date|null) => void
  farm: string
  setFarm: (farm: string) => void
  nutritionist: string
  setNutritionist: (nutritionist: string) => void
  notes: string
  setNotes: (notes: string) => void
  isEmail: boolean
  setIsEmail: (isEmail: boolean) => void
  email: string
  setEmail: (email: string) => void
}


export function Controls(props: {
  currencies: Currency[]
  feedingRates: ProductFeedingRate[]
  isPdf: boolean
  pdfRef?: React.RefObject<HTMLDivElement>
  products: Product[]
  notesState: NotesState
}) {
  const [emailSent, setEmailSent] = React.useState<boolean|undefined>(false)
  const [pdfDialogOpen, setPdfDialogOpen] = React.useState(false)

  const { initialValues, setValues, submitForm, values } = useFormikContext<valuesType>()

  const currency = props.currencies.find(c => c.id === values.currencyId) as Currency

  React.useEffect(() => {
    if (!pdfDialogOpen) {
      setEmailSent(false)
    }
  }, [pdfDialogOpen])

  async function convertCurrencyInputs(prevRate: number, currency: Currency, newValues: valuesType) {
    const initialProductPrice = props.products.find(p => p.id === newValues.productId)!.price
    await setValues(renderValues({
      ...newValues,
      productPrice: initialProductPrice * currency.conversionRate,
      investmentDmCostCurrentFarOff: convertCurrency(newValues.investmentDmCostCurrentFarOff, prevRate, currency),
      investmentDmCostCurrentCloseUp: convertCurrency(newValues.investmentDmCostCurrentCloseUp, prevRate, currency),
      investmentDmCostCurrentFresh: convertCurrency(newValues.investmentDmCostCurrentFresh, prevRate, currency),
      investmentDmCostCurrentEarlyMidLact: convertCurrency(newValues.investmentDmCostCurrentEarlyMidLact, prevRate, currency),
      investmentDmCostCurrentLateLact: convertCurrency(newValues.investmentDmCostCurrentLateLact, prevRate, currency),
      shortTermReturnsMilkFmmoPrice: convertCurrency(newValues.shortTermReturnsMilkFmmoPrice, prevRate, currency),
      shortTermReturnsFatFmmoPrice: convertCurrency(newValues.shortTermReturnsFatFmmoPrice, prevRate, currency),
      shortTermReturnsProteinFmmoPrice: convertCurrency(newValues.shortTermReturnsProteinFmmoPrice, prevRate, currency),
      shortTermReturnsOtherSolidsFmmoPrice: convertCurrency(newValues.shortTermReturnsOtherSolidsFmmoPrice, prevRate, currency),
      shortTermReturnsPremiumFmmoPrice: convertCurrency(newValues.shortTermReturnsPremiumFmmoPrice, prevRate, currency),
      longTermReturnsCalfValueCurrent: convertCurrency(newValues.longTermReturnsCalfValueCurrent, prevRate, currency),
      longTermReturnsReproductionCostCurrent: convertCurrency(newValues.longTermReturnsReproductionCostCurrent, prevRate, currency),
      longTermReturnsReplacementCostCurrent: convertCurrency(newValues.longTermReturnsReplacementCostCurrent, prevRate, currency),
      longTermReturnsCullValueCurrent: convertCurrency(newValues.longTermReturnsCullValueCurrent, prevRate, currency),
      longTermReturnsMastitisCostPerCase: convertCurrency(newValues.longTermReturnsMastitisCostPerCase, prevRate, currency),
      longTermReturnsKetosisClinicalCostPerCase: convertCurrency(newValues.longTermReturnsKetosisClinicalCostPerCase, prevRate, currency),
      longTermReturnsKetosisSubclinicalCostPerCase: convertCurrency(newValues.longTermReturnsKetosisSubclinicalCostPerCase, prevRate, currency),
      longTermReturnsMetritisCostPerCase: convertCurrency(newValues.longTermReturnsMetritisCostPerCase, prevRate, currency),
    }, currency.code), false)
  }

  async function convertMassInputs(units: Units, newValues: valuesType, currencyCode: string, submit=true) {
    const k = units === Units.IMPERIAL ? 2.20462 : 1 / 2.20462
    function convert(value: number, reverse?: boolean) {
      return value * (reverse ? 1 / k : k)
    }
    const convertedValues = renderValues(
      {
        ...newValues,
        investmentDmiFarOff: convert(newValues.investmentDmiFarOff),
        investmentDmiCloseUp: convert(newValues.investmentDmiCloseUp),
        investmentDmiFresh: convert(newValues.investmentDmiFresh),
        investmentDmiEarlyMidLact: convert(newValues.investmentDmiEarlyMidLact),
        investmentDmiLateLact: convert(newValues.investmentDmiLateLact),
        investmentDmCostCurrentFarOff: convert(newValues.investmentDmCostCurrentFarOff, true),
        investmentDmCostCurrentCloseUp: convert(newValues.investmentDmCostCurrentCloseUp, true),
        investmentDmCostCurrentFresh: convert(newValues.investmentDmCostCurrentFresh, true),
        investmentDmCostCurrentEarlyMidLact: convert(newValues.investmentDmCostCurrentEarlyMidLact, true),
        investmentDmCostCurrentLateLact: convert(newValues.investmentDmCostCurrentLateLact, true),
        shortTermReturnsMilkCurrent: convert(newValues.shortTermReturnsMilkCurrent),
        shortTermReturnsMilkFmmoPrice: convert(newValues.shortTermReturnsMilkFmmoPrice, true),
        shortTermReturnsFatFmmoPrice: convert(newValues.shortTermReturnsFatFmmoPrice, true),
        shortTermReturnsProteinFmmoPrice: convert(newValues.shortTermReturnsProteinFmmoPrice, true),
        shortTermReturnsOtherSolidsFmmoPrice: convert(newValues.shortTermReturnsOtherSolidsFmmoPrice, true),
        shortTermReturnsPremiumFmmoPrice: convert(newValues.shortTermReturnsPremiumFmmoPrice, true),
        longTermReturnsCullValueCurrent: convert(newValues.longTermReturnsCullValueCurrent, true),
        longTermReturnsMinimumMilkToBreedCurrent: convert(newValues.longTermReturnsMinimumMilkToBreedCurrent),
      },
      currencyCode
    )
    if (submit) {
      await setValues(convertedValues, false)
    }
    return convertedValues
  }

  async function generatePdf() {
    if (!props.pdfRef || !props.pdfRef.current) return

    const canvas = await html2canvas(props.pdfRef.current, {useCORS: true})
    const pdf = new JsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: [canvas.width, canvas.height],
    })
    const imgData = canvas.toDataURL('image/png')
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height)
    return pdf
  }

  async function handleDownloadPdf() {
    const pdf = await generatePdf()
    if (!pdf) return
    pdf.save('report.pdf')
    setPdfDialogOpen(false)
  }

  async function sendEmailPdf() {
    setEmailSent(undefined)
    const pdf = await generatePdf()
    if (!pdf) return
    const formData = new FormData()
    formData.append('file', pdf.output('blob'), 'report.pdf')
    formData.append('email', props.notesState.email)
    await fetch(
      '/api/send-email',
      {
        body: formData,
        headers: {'X-CSRFToken': window.GLOBAL_CONFIG.CSRF},
        method: 'POST',
      },
    )
    setEmailSent(true)
  }

  return (
    <mui.Grid container item spacing={2} mt={1}>
      <mui.Grid container item spacing={2.5}>
        <mui.Grid container item xs={props.isPdf ? 4 : 12} spacing={2.5}>
          <mui.Grid item xs={props.isPdf ? 12 : 4}>
            <NumberField
              name="aabCents"
              label="AAB Savings"
              fullWidth
              InputProps={{
                size: 'small',
                endAdornment: <mui.InputAdornment position="end">
                  <mui.Typography sx={{fontSize: '.6rem', color: '#000!important'}}>
                    cents/cow/d
                  </mui.Typography>
                </mui.InputAdornment>,
              }}
              sx={{
                mt: '2px',
                '& label': {
                  color: '#006600!important',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  mt: props.isPdf ? '-.5rem' : '-.2rem',
                },
                '& .MuiInputBase-root': {
                  pt: props.isPdf ? '8px' : 0,
                  backgroundColor: '#D9F2D0',
                  color: '#006600',
                  pr: '8px',
                },
                '& .MuiInputAdornment-root p': {
                  color: '#006600',
                  mt: props.isPdf ? '-8px' : 0,
                },
                '& fieldset': {
                  borderColor: '#006600!important',
                  ...(props.isPdf ? {
                    top: 0,
                    '& span': {display: 'none'},
                    '& legend': {display: 'none'},
                  } : {}),
                },
                '& input': {
                  pl: '10px',
                  py: props.isPdf ? 0 : .5,
                },
              }}
            />
            <mui.Typography sx={{fontSize: '0.6rem', color: '#006600'}}>
              * for a reformulated diet
            </mui.Typography>
          </mui.Grid>
          {(!props.isPdf || values.isPlusDmi) &&
            <mui.Grid item xs={props.isPdf ? 12 : 4}>
              <mui.Box sx={{position: 'relative'}}>
                <mui.Box component="label" sx={{
                  position: 'absolute',
                  top: 'calc(-1rem - 5px)',
                  left: '50%',
                  transform: 'translateX(-52%)',
                  px: .5,
                  color: '#EC671F',
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                  zIndex: 1,
                }}><span style={{color: '#FFB66D'}}>+</span>DMI</mui.Box>
                <ToggleButton
                  name="isPlusDmi"
                  label={<mui.Box sx={{my: '5px', lineHeight: 1.25}}>
                    Close-up & Fresh
                  </mui.Box>}
                  sx={{
                    p: 0,
                    ...volumeSx(props.isPdf, '#EA8D00', '#FEF5F0'),
                  }}
                />
              </mui.Box>
            </mui.Grid>
          }
          <mui.Grid item xs={props.isPdf ? 12 : 4}>
            {props.isPdf
              ? <>
                <mui.Typography
                  sx={{
                    color: '#FF6699',
                    fontSize: '1rem',
                    mt: 'calc(-1rem - 3px)',
                    mb: '-2px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  Herd Size
                </mui.Typography>
                <mui.Typography
                  sx={{
                    backgroundColor: '#FFE6FF',
                    border: '1px solid #FF6699',
                    borderRadius: '4px',
                    py: '6px',
                    pb: 1,
                    color: '#FF6699',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  {values.herdSize}
                </mui.Typography>
              </>
              : <NumberField
                  name="herdSize"
                  label="Herd Size"
                  fullWidth
                  InputProps={{size: 'small'}}
                  sx={{
                    '& label': {
                      color: '#FF6699!important',
                      fontWeight: 'bold',
                      mt: '-.25rem',
                    },
                    '& .MuiInputBase-root': {
                      backgroundColor: '#FFE6FF',
                      color: '#FF6699',
                    },
                    '& .MuiInputAdornment-root p': {
                      color: '#FF6699',
                    },
                    '& fieldset': {
                      borderColor: '#FF6699!important',
                    },
                    '& input': {
                      textAlign: 'center',
                    },
                  }}
                />
            }
          </mui.Grid>
        </mui.Grid>
        {props.isPdf &&
          <mui.Grid container item xs={8}>
            <mui.Grid item xs={12} sx={{
              backgroundColor: '#F5F5F5',
              color: '#7F7F7F',
              borderRadius: '20px',
              padding: 2,
              lineHeight: 1.15,
            }}>
              <mui.Typography
                style={{whiteSpace: 'pre-wrap'}}
                sx={{
                  fontSize: '0.8rem',
                  lineHeight: 1.15,
                }}
              >
                <b>Farm:</b> {props.notesState.farm}<br/>
                <b>Nutritionist:</b> {props.notesState.nutritionist}<br/>
                <b>Assessed on:</b> {props.notesState.selectedDate?.toLocaleDateString()}<br/><br/>
                <b>Notes:</b> {props.notesState.notes}
              </mui.Typography>
            </mui.Grid>
          </mui.Grid>
        }
      </mui.Grid>
      {!props.isPdf && <>
        <mui.Grid item xs={12}>
          <mui.Stack gap={3} direction="row">
            <ToggleButtons
              fullWidth
              isPdf={props.isPdf}
              name="resultsUnitsAmount"
              optionsRows={[[
                {label: 'Per Cow', value: ResultsUnitsAmount.COW},
                {label: 'Per Herd', value: ResultsUnitsAmount.HERD},
              ]]}
              sx={{justifyContent: 'center'}}
            />
            <ToggleButtons
              fullWidth
              isPdf={props.isPdf}
              name="resultsUnitsPeriod"
              optionsRows={[[
                {label: 'Day', value: ResultsUnitsPeriod.DAY},
                {label: 'Month', value: ResultsUnitsPeriod.MONTH},
                {label: 'Year', value: ResultsUnitsPeriod.YEAR},
              ]]}
              sx={{justifyContent: 'center'}}
            />
          </mui.Stack>
        </mui.Grid>
        <mui.Grid item xs={12}>
          <mui.Stack gap={3} direction="row" alignItems="center">
            <mui.Button
              onClick={() => {
                const initialFeedingRates = props.feedingRates.find(p => p.product.id === values.productId)
                const valuesToPreserve = {
                  units: values.units,
                  resultsUnitsAmount: values.resultsUnitsAmount,
                  resultsUnitsPeriod: values.resultsUnitsPeriod,
                  currencyId: values.currencyId,
                  herdSize: values.herdSize,
                  investmentProductGramsPerCowDayCloseUp: initialFeedingRates!.closeUp,
                  investmentProductGramsPerCowDayFresh: initialFeedingRates!.fresh,
                  investmentProductGramsPerCowDayEarlyMidLact: initialFeedingRates!.earlyMidLact,
                  investmentProductGramsPerCowDayLateLact: initialFeedingRates!.lateLact,
                  productId: values.productId,
                  regionId: values.regionId,
                }

                function update(values: valuesType) {
                  convertCurrencyInputs(
                    1,
                    currency,
                    values,
                  ).then(submitForm)
                }

                if (values.units === Units.IMPERIAL) {
                  update({...initialValues, ...valuesToPreserve})
                } else {
                  convertMassInputs(values.units, {...initialValues, ...valuesToPreserve}, currency.code, false)
                    .then(convertedValues => update(convertedValues))
                }
              }}
              sx={{
                width: '70px',
                py: 0,
                lineHeight: '1',
                fontWeight: 'bold',
                textTransform: 'none',
                ...volumeSx(props.isPdf, '#D9E5F8', '#0F4861'),
            }}
            >
              Reset Form
            </mui.Button>
            <ToggleButtons
              name="units"
              isPdf={props.isPdf}
              fullWidth
              onChange={async (e, newValue) => await convertMassInputs(newValue as Units, values, currency.code)}
              optionsRows={[
                [
                  {label: 'LB', value: Units.IMPERIAL},
                  {label: 'KG', value: Units.METRIC},
                ],
              ]}
              sx={{justifyContent: 'center'}}
            />
            <ToggleButtons
              isPdf={props.isPdf}
              name="currencyId"
              colorLink="#B3B280"
              // colorActive="#666400"
              buttonColor="#e5e4ae"
              fullWidth
              onChange={async (e, newValue) => {
                const oldCurrency = props.currencies.find(c => c.id === values.currencyId) as Currency
                const newCurrency = props.currencies.find(c => c.id === newValue) as Currency
                await convertCurrencyInputs(oldCurrency.conversionRate, newCurrency, values)
              }}
              optionsRows={[props.currencies.map(c => ({label: c.sign, value: c.id}))]}
              sx={{justifyContent: 'center'}}
              buttonSx={{fontSize: '1rem', lineHeight: '1.25'}}
            />
          </mui.Stack>
        </mui.Grid>
      </>}
      {!props.isPdf &&
        <mui.Box sx={{
          textAlign: 'center',
          mt: .5,
          width: '100%',
        }}>
          <mui.IconButton onClick={() => {
            props.notesState.setIsEmail(false)
            setPdfDialogOpen(true)
          }}>
            <img src={pdfIcon} alt="Download PDF" height="50"/>
          </mui.IconButton>
          <mui.IconButton onClick={() => {
            props.notesState.setIsEmail(true)
            setPdfDialogOpen(true)
          }}>
            <img src={emailIcon} alt="Email PDF" height="50"/>
          </mui.IconButton>
        </mui.Box>
      }
      <mui.Dialog
        component="form"
        open={pdfDialogOpen}
        onClose={() => setPdfDialogOpen(false)}
        onSubmit={e => {
          e.preventDefault()
          if (props.notesState.isEmail) {
            sendEmailPdf().then()
          } else {
            handleDownloadPdf().then()
          }
        }}
        maxWidth="sm"
        fullWidth
      >
        {!emailSent
          ? <mui.DialogContent>
              <mui.TextField
                margin="dense"
                label="Farm"
                fullWidth
                variant="standard"
                value={props.notesState.farm}
                onChange={(v) => props.notesState.setFarm(v.target.value.slice(0, 20))}
              />
              <mui.TextField
                margin="dense"
                label="Nutritionist"
                fullWidth
                variant="standard"
                value={props.notesState.nutritionist}
                onChange={(v) => props.notesState.setNutritionist(v.target.value.slice(0, 15))}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Assessed on"
                  value={props.notesState.selectedDate}
                  onChange={(date) => props.notesState.setSelectedDate(date)}
                  sx={{mt: 1}}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      variant: 'standard',
                    },
                  }}
                />
              </LocalizationProvider>
              <mui.TextField
                margin="dense"
                label="Notes"
                fullWidth
                variant="standard"
                multiline
                rows={3}
                value={props.notesState.notes}
                onChange={(v) => props.notesState.setNotes(v.target.value)}
              />
              {props.notesState.isEmail &&
                <mui.TextField
                  margin="dense"
                  type="email"
                  label="Email"
                  fullWidth
                  required
                  variant="standard"
                  value={props.notesState.email}
                  onChange={(v) => props.notesState.setEmail(v.target.value)}
                />
              }
            </mui.DialogContent>
          : <>
              <mui.DialogTitle>Email sent</mui.DialogTitle>
              <mui.DialogContent>The PDF report was sent to {props.notesState.email}</mui.DialogContent>
            </>
        }

        {!emailSent
          ? <mui.DialogActions sx={{p: 3, pt: 1}}>
              <mui.Button color="error" onClick={() => setPdfDialogOpen(false)}>Cancel</mui.Button>
              <mui.Button
                color="success"
                variant="contained"
                type="submit"
                disabled={emailSent !== false}
              >
                {props.notesState.isEmail
                  ? 'Send PDF'
                  : 'Download PDF'
                }
              </mui.Button>
            </mui.DialogActions>
          : <mui.DialogActions sx={{p: 3, pt: 1}}>
              <mui.Button onClick={() => setPdfDialogOpen(false)}>Close</mui.Button>
            </mui.DialogActions>
        }
      </mui.Dialog>
    </mui.Grid>
  )
}