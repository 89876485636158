import {createTheme} from '@mui/material/styles'


export const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          fontSize: '0.875rem'
        },
      },
    },
  },
  typography: {
    h1: {
      fontSize: '2.375rem',
      fontWeight: 700,
      letterSpacing: 'normal',
      lineHeight: 1.5,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
      letterSpacing: 'normal',
      lineHeight: 1.7,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 600,
      letterSpacing: 'normal',
      lineHeight: 1.5,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 600,
      letterSpacing: 'normal',
      lineHeight: 1.5,
    },
    body1: {
      fontSize: '1.25rem',
      fontWeight: 400,
      letterSpacing: 'normal',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: 'normal',
      lineHeight: 1.5,
    },
    caption: {
      fontSize: '0.875rem',
      fontWeight: 400,
      letterSpacing: 'normal',
      lineHeight: 1.7,
    },
  },
})
